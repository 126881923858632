@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Roboto&display=swap');
/* || reset */
* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

/* || general settings  */
body{
  font-family: 'Chakra Petch', sans-serif;
  font-size: 1rem;
  background: radial-gradient(rgb(216, 216, 216), #7b81c2);
}

h1, h2, h4{
  text-align: center;
  margin: 10px  auto;
}

input{
  border-style: none;
}

button{
  height: 25px;
  border-radius: 5px;
  margin: .1rem .2rem;
  padding: .2rem;
  background-color:rgba(161, 204, 227, 0.58);
  cursor: pointer;
  border-style: none;
}

a {
  font-weight: 500;
  text-decoration: none;
  font-weight: 500;
  color:rgb(0, 0, 0);
  font-family:'Chakra Petch', sans-serif;
}

a:visited{
    color:rgb(0, 0, 0);
}
a:hover {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

a:active {
  color: rgb(11, 11, 10);
}

ol li {
  list-style-type:none;
  padding: 2px 2px 2px 0;
}


input[type="checkbox"]{
  display: inline-block;
  width: 20px;
}

input, select, textarea{
  width: 100%;
  border-radius: 5px;
  outline: none;
  caret-color: rgb(255, 55, 0);
}

input, select{
  height: 30px;
  padding-left: 10px;
}

textarea{
  resize: none;
  padding: 10px;
  max-width: 100%;
  line-height: 1.5;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}



/* || custom classes */
.navbar a:hover{
  text-decoration: none;
  color: white;
}

/* nav bar and log */
.navbar{
  background-color: #afa9d5bc;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.366);
  border-radius:  0 0 .5rem .5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  backdrop-filter: blur(2px);
  z-index: 1;
}

.navbar ul a{
    font-size: 1.2rem;
    white-space: nowrap;
}

.logo{
  font-family: 'Chakra Petch', sans-serif;
  color: #424f53;
  font-size:1.2rem;
  font-weight: 600;
  /* background-color: rgb(200, 192, 192); */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  padding-left: 1rem;
}

.nav-links{
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  gap: 1rem;
}

.nav-links a {
  font-family: 'Chakra Petch', sans-serif;
}


.footer {
  position: sticky;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  background-color: #afa9d5bc;
  z-index: 1;
  height: 3rem;
  color: #333;
  font-size: 1.1em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: .2rem .2rem 0 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.366);
}


.warning{
  background-color: #e95a3daf;
  margin-left: 10px;
}

.node{
  position: relative;
  border-radius: 50%;
  background: #f5ebd2d3;
  margin: 0 auto;
  width: 280px;
  height: 280px;
  word-wrap:  break-word;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap:1rem;
  box-shadow: 0 4px 50px rgb(22, 22, 22);
  word-break: break-all;
}

.node button{
  white-space: nowrap;
}

.public-wrapper, .about-wrapper{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.top{
  margin-top: 20px;
  /* background-color: #776BCC; */
}


.bottom{
  margin-bottom: 10px;
  /* background-color: purple; */
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 5px;
  align-content: center;
}

.middle{
  background: radial-gradient(rgba(91, 193, 198, 0.479), #f5ebd2d3);
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-size:large;
  font-family:Arial, Helvetica, sans-serif;
  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  margin: 2px 10px;
  border-radius: 30px;
}

.satelliteNode{
  font-size: 1rem;
  min-width: 150px;
  max-width: 150px;
  word-wrap: break-word;
  min-height: 150px;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  margin-bottom: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.366);
  word-break: break-all;
}

.satelliteNexus{
  background-color: rgba(240, 255, 255, 0.496);
  min-width: 150px;
  max-width: 300px;
  height: 2rem;
  word-wrap:  break-word;

  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 5px 50px 50px 5px;
  color: rgb(58, 57, 56);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.inbound {
  background: rgba(180, 110, 217, 0.296); 
}

.outbound {
  background:rgba(110, 198, 230, 0.29);
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

.nodeNexusWrapper{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap:1rem;
}

.node-nexus{
  display: table-cell;
  vertical-align: middle;
  /* background-color: purple; */
  min-width: 100px;
}

.node-nexus input{
  width: 120px;
  border-style: none;
  background-color: white;
  text-align: center;
}

.node-nexus select{
  width: 120px;
  background: rgba(255, 255, 255, 0.985);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-style: none;
}

.center {
  width: 100%;
  grid-area: center;
  position: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.left{}

.right{}


.hidden{
  visibility: hidden;
}

.show{
  color: rgb(66, 66, 66);
  padding: 10px;
  background: rgba(255, 255, 255, 0.105);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(28, 28, 28, 0.3);
}

.missing-background{
  background-image: url("/public/img/singularity.gif");
  color: white;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.dash-container{
  min-height: 100vh;
  margin: 0 auto;
}

.content-wrapper{
  position: relative;
  min-height: 100vh;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(27, 27, 27, 0.3);
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.content-wrapper h3, p{
  width: 100%;
}

.content-wrapper a{
  color: rgb(28, 27, 27);
  font-size: 1.2rem;
}

.content-wrapper a:hover{
  color:whitesmoke;
  font-weight: 800;
  text-decoration: none;
  background-color: rgba(232, 201, 110, 0.773);
  border-radius: 3px;
}

.profile-grid-container{
  /* background-color: red; */
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.indexList{
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-style: italic;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  background-color: #553b5652;
  position: sticky;
  top:4rem;
  left:0;
}

.grid-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.grid-item{
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.grid-item ol{
  color: white;
}

.modal{
  position: absolute;
  top: 0px;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(27, 27, 27, 0.3);
  z-index: 2;
}

.addNexus, .edit-node, .addDef{
  background: rgba(92, 94, 92, 0.592);
  padding: 0px 5px;
  backdrop-filter: blur(3px);
  height: auto;
  margin: 0 auto;
}

.editNode textarea, input, select{
  background-color: white;
}

.addDef textarea, select{
  background-color: white;
}

.form{
  padding-left: 10px;
  padding-right: 10px;
}

.sign-in{
  padding-top: 10vh;
  margin: 0 auto;
}

.errorsWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.errmsg{
  color:#f95523;
}

.example{
  color:#dcf36a;
}

.search-wrapper{
  position: relative;
  /* background-color: red; */
}

.search-result{
  overflow: auto;
  height: 100px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(27, 27, 27, 0.3);
  display: flex;
  flex-flow: row wrap;
  justify-content:flex-start;
  align-items: center;
}

.individual-word{
  margin-right: 5px;
  background-color: rgba(141, 204, 236, 0.34);
}

.individual-word a{
  font-size: .9rem;
}

.add-node{
  margin: 0 auto;
  padding: 1rem 1rem;
  /* border-style: dashed; */
}

.selected{
  background-color: #dcf36a;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.instructions ol{
  color: black;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.line {
    display: inline-block;
}

.about{
  width: 95vw;
  background-color: rgba(255, 255, 255, 0.635);
  padding: .5rem;
  border-radius: 5px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about p{
  /* background-color: red; */
  position: relative;
  margin: 1rem;
  text-align: justify;
  text-justify: inter-word
}

.instruction img{
  position: relative;;
  max-width: 90vw;
  max-height: 250px;
  overflow: hidden;
  /* max-height: 280px; */
  display: block;
  margin: 0 auto;
}

/* || desktop  */
@media only screen and (min-width: 768px) {
  .profile-grid-container{
    flex-direction: row;
    align-items: flex-start;
  }

  .grid-container{
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .grid-item{
    /* background-color: rgb(236, 169, 236); */
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: stretch; */
    /* align-content: center;
    align-items: center; */
  }

  .grid-item button{
    width: auto;
  }

  .container {
    flex-direction: row;
    justify-content:space-evenly;
  }

  /* only change .center to relative */
  .center{
    position: relative;
  }

  .left, .center, .right{
    width: 33%;
  }

  .sign-in{
    width: 50%; 
  }


  /* about component */

  .about{
    width: 80vw;
    padding: 1rem;
  }

  .instruction img{
    max-width: 70vw;
    max-height: 380px;
    display: block;
    margin: 0 auto;
  }

}
